/* primary color #009992 */

#root {
  background: linear-gradient(200deg, #67dbce, #009992);
}

.main {
  background-color: #eee;
}

a {
  color: #009992;
}

.btn-primary,
.page-item.active .page-link,
.bg-primary,
.badge-primary,
.notif--success,
.btn-outline-primary:hover {
  background-color: #009992 !important;
  border-color: #009992;
}

.btn-outline-primary {
  color: #009992;
  border-color: #009992;
}

.btn-primary:hover,
.badge-secondary {
  background-color: #009992;
  border-color: #009992;
}

.app-header .navbar-brand img {
  width: 140px;
}

#login-logo > * {
  line-height: 17px;
  color: white;
}

#login-logo h1 {
  font-weight: bold;
}

#login-logo h2 {
  font-size: 1.4rem;
}

#login-logo {
  margin-bottom: 50px;
}

.sidebar {
  background: #00423f;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  background: #009a93;
}

.rednot--container {
  top: 40px;
  position: fixed;
}

.card-header {
  background: #009a93;
}

img {
  max-width: 100%;
}

.FileInput img {
  max-height: 200px;
}

.content ul {
  padding-left: 30px;
}
